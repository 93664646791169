@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  font-family: "Inter", sans-serif;
  /* font-family: "Libre Baskerville", sans-serif; */
  font-weight: 300;
  /*background-image: url("/public/background.png");*/
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  background-attachment: fixed;
  background-position: center top;
  position: relative; /* Standard positioning */
  margin: 0; /* Reset default margin */
  font-size: 0.8rem;
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Libre Baskerville", serif; */
  font-family: "Lato Black", sans-serif;
  /* font-family: "Libre Baskerville", sans-serif; */
  /*font-weight: 500;*/
  font-style: normal;
  font-variation-settings: "slnt" 0;
  color: black !important;
}

h1 {
  font-size: 1.6rem;
}

h2 {
  font-size: 1.4rem;
}

p {
  color: black !important;
  /* font-family: "Libre Baskerville", sans-serif; */
  font-family: "Inter", sans-serif;
}

#landing-page p,
#landing-page h1,
#landing-page h2,
#landing-page h3,
#landing-page h4,
#landing-page h5,
#landing-page h6 {
  font-family: "Libre Baskerville", sans-serif !important;
}

.sm {
  font-size: 0.8rem;
}

@media (min-width: 1200px) {
  .normal.container-xl {
    max-width: 92% !important;
    margin: 0 !important;
  }
}

@media (min-width: 1200px) {
  .container-xl {
    max-width: 92% !important;
    margin-left: 5% !important;
  }
}

table {
  border: 10px transparent;
  border-radius: 0.4375rem !important;
  border-collapse: separate; /* Necessary for border-radius to work on tables */
  border-spacing: 0;
  color: black !important;
  /*overflow: hidden;*/
}

table th {
  color: black !important;
}

.thead-light tr:first-child th:first-child {
  border: 10px transparent;
  border-top-left-radius: 0.4375rem !important;
}

.thead-light tr:first-child th:last-child {
  border: 10px transparent;
  border-top-right-radius: 0.4375rem !important;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgb(251, 251, 251);
  /* background-color: rgb(244, 251, 255); */
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #eff9ff;
}

.ag-theme-quartz .ag-row.ag-row-even {
  background-color: rgb(244, 251, 255);
}

.ag-theme-quartz .ag-row.ag-row-odd {
  background-color: #e9f9ff; /* White, or change as needed for odd rows */
}

.ag-theme-quartz {
  /* font-family: "Libre Baskerville", sans-serif !important; */
  font-family: "Libre Baskerville", sans-serif !important;
  font-size: 0.75rem !important;
  /*--ag-grid-size: 5px !important;*/
  /*line-height: 0.6rem !important;*/
}

.ag-theme-quartz .ag-header-cell-label {
  /* font-family: "Libre Baskerville", sans-serif !important; */
  font-family: "Libre Baskerville", sans-serif !important;
  font-weight: bold !important;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}

.alert-header {
  width: 200px !important;
}

.table-hover tr:hover {
  background-color: #d8f2ff !important;
  color: black !important;
}

.col h6 {
  color: #989898;
}

.col h5 {
  color: #757575;
}

th,
a {
  cursor: pointer;
}

th,
td {
  white-space: normal !important;
  /*word-wrap: break-word !important;*/
}

.clipped {
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expanded {
  max-height: none;
}

.sub-row {
  background-color: #f7f7f7ff !important;
}

::-webkit-scrollbar {
  width: 0.8rem !important;
  height: 0.8rem !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

table ::-webkit-scrollbar {
  width: 0.2rem !important;
  height: 0.2rem !important;
}

.thin-webkit ::-webkit-scrollbar {
  width: 0.2rem !important;
  height: 0.2rem !important;
}

.bg-warning {
  background-color: #ffad00 !important;
}

.badge-warning {
  color: #ffad00 !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.badge-pill {
  font-size: 1rem;
  padding: 0.8rem 1.8rem 0.8rem 1.8rem;
}

.badge-dot {
  color: #525f7f;
}

.bg-cream {
  background-color: #f1f4fa !important;
}

.bg-cream2 {
  background-color: #fcf8f1 !important;
}

.card {
  background-color: #f3f4f6;
}

.card-header {
  background-color: #f9fafb;
}

.form-control {
  color: black;
}

.form-control:focus {
  color: black;
}

.-mouse-pointer {
  cursor: pointer;
}

#chatbot {
  color: #fff !important;
}

#chatbot pre {
  color: #fff !important;
}

#chatbot p {
  color: inherit !important;
}

#chatbot .sc-cwHptR {
  max-width: 750px;
  min-width: 80%;
}

#chatbot .sc-Nxspf {
  padding-right: 0;
}

#chatbot .sc-koXPp {
  padding-right: 0;
}

#chatbot .sc-eBMEME {
  padding-right: 0;
}

#chatbot .sc-bmzYkS {
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
}

#chatbot p {
  font-size: 14px;
  margin-bottom: 0;
  display: inline;
}

.chatbot p {
  font-size: 14px;
  margin-bottom: 0;
  display: inline;
}


#chatbot table,
#chatbot table th {
  color: #fff !important;
}

#chatbot table td,
table th {
  border: 1px solid #fff;
}

[data-testid="outgoing-message"] .bPhtfT {
  padding-right: 25px;
}
