.main-content {
  position: relative;

  // Navbar
  .navbar-top {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // // Container
  .container-fluid {
    @include media-breakpoint-up(md) {
      padding-left: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
      padding-right: (
        $main-content-padding-x + $grid-gutter-width / 2
      ) !important;
    }
  }

  // // Container
  // .container-fluid {
  //     @include media-breakpoint-up(md) {
  //         // Adjust the width to accommodate the sidebar
  //         width: calc(100% - #{$navbar-vertical-open-width}); // Assuming the sidebar's width is fixed
  //         padding-left: 0; // No left padding
  //         padding-right: 0; // No right padding
  //     }
  // }
}
